import React from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import clsx from 'clsx';
import axios from 'axios';
import { Link } from "react-router-dom"

import { ValidatePassword } from '../../utils'
import logo from '../../logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    btn: {
        color: '#b1ffb1'
    },
    error: {
      fontSize: 'small',
      color: 'red'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em'
    },
    boxCenter: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
}));

const ValidationTextField = withStyles({
    root: {
        margin: '1em auto',
      '& input:valid + fieldset': {
        borderColor: '#1ac3a3',
      },
      '& label.Mui-focused': {
        color: '#1ac3a3',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#1ac3a3',
        },
        '&:hover fieldset': {
          borderColor: '#1ac3a3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1ac3a3',
        },
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
      },
      '& input:valid:focus + fieldset': {
        // borderLeftWidth: 6,
        // padding: '4px !important', // override inline-style
      },
    },
})(TextField);

const ProgressCircle = withStyles({
  root: {
      color: 'white'
  }
})(CircularProgress)

const Reset = (props) => {
    const classes = useStyles(props)
    const [mounted, setMounted] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const [jwt, setJwt] = React.useState('');
    const onMount = async () => {
            if (!mounted) {
                setMounted(true)
                let tempArr = window.location.href.split('?')
                if (tempArr[1]) {
                  tempArr = tempArr[1].split('&')
                  tempArr = tempArr.map((query) => {
                      const nestedArr = query.split('=')
                      if (nestedArr[0] === 'redirectUrl') setRedirectUrl(nestedArr[1])
                      if (nestedArr[0] === 'clientId') setClientId(nestedArr[1])
                      if (nestedArr[0] === 'jwt') setJwt(nestedArr[1])
                      return nestedArr
                  })
                }
            }
    }
    onMount()
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [unmatchPassword, setUnmatchPassword] = React.useState(false);
    const [passwordErrMessage, setPasswordErrMessage] = React.useState('');
    const [passwordUpdated, setPasswordUpdated] = React.useState(false);
    const passwordChange = (event) => {
        setPasswordErrMessage('')
        const validation = ValidatePassword(event.target.value)
        if (!validation) {
            setPasswordErrMessage('Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.')
          }
        setPassword(event.target.value)
    }
    const confirmPasswordChange = (event) => {
        setUnmatchPassword(false)
        setConfirmPassword(event.target.value)
        if (event.target.value !== password) {
            setUnmatchPassword(true)
        }
    }
    const [generalErr, setGeneralErr] = React.useState('');
    const changePassword = async () => {
        setGeneralErr('')
        if (unmatchPassword) return
        if (passwordErrMessage) return
        setLoading(true)
        try {
            const changePassRes = await axios.post(
                `${process.env.REACT_APP_SSO_API_URL}/password/reset`,
                {
                    "newPassword": password
                },
                {
                    headers: { "Authorization": `Bearer ${jwt}`}
                }
            )
            if (changePassRes.data === "OK") {
                setLoading(false)
                setPasswordUpdated(true)
            } else {
                if (changePassRes.data.message.length > 9) setGeneralErr(changePassRes.data.message)
                setLoading(false)
            }
        } catch (err) {
            setGeneralErr(err || 'Failed to update password. Please contact support')
            setLoading(false)
        }
    }
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize</Typography>
            {!passwordUpdated && <Box m={2} variant="middle" className={classes.box} >
              <Typography className={classes.text}>Enter your new password.</Typography> 
              <ValidationTextField
                  onChange={passwordChange}
                  disabled={loading}
                  error={passwordErrMessage ? true: false}
                  className={classes.root}
                  label="New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
              />
              {passwordErrMessage ? <Typography className={classes.error}>{passwordErrMessage}</Typography> : '' }
              <ValidationTextField
                  onChange={confirmPasswordChange}
                  disabled={loading}
                  error={unmatchPassword}
                  className={classes.root}
                  label="Confirm New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
              />
              {unmatchPassword ? <Typography className={classes.error}>Passwords do not match</Typography> : '' }
              <Button className={classes.introBtn} onClick={changePassword}>{loading ? <ProgressCircle/> : 'Change Password'}</Button><br/><br/>
                {generalErr ? <Typography className={classes.error}>{generalErr}</Typography> : ''}
          </Box>}
          {
            passwordUpdated && <Box m={2} variant="middle" className={classes.boxCenter} >
                <Typography variant="h5" className={classes.title}>Password Changed</Typography><br/><br/>
                <Typography className={classes.text}>Back to <Link className={classes.green} to={`/signin?${redirectUrl ? "redirectUrl="+redirectUrl:''}${clientId ? `&clientId=${clientId}` : ''}`}>Sign in</Link></Typography> 
            </Box>
          }
      </Container>
    )
}

export default Reset
