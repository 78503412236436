import React from 'react'
import Particles from "react-particles";
import { Engine } from "tsparticles-engine";
import { loadTrianglesPreset } from "tsparticles-preset-triangles";
import { useCallback } from "react";
// import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Route, Switch, HashRouter as Router } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import SignIn from './components/SignIn/SignIn'
import SignUp from './components/SignUp/SignUp'
import Forgot from './components/Forgot/Forgot'
import Verify from './components/Verify/Verify'
import Consent from './components/Consent/Consent'
import Reset from './components/Reset/Reset'
import UserOrg from './components/UserOrg/UserOrg'

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    btn: {
        color: '#b1ffb1'
    },
    title: {
      textAlign: 'center',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
    },
    green: {
      color: '#1ac3a3'
    },
    text: {
      color: '#3c4257'
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em'
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000,
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
}));

// const ValidationTextField = withStyles({
//     root: {
//         margin: '1em auto',
//       '& input:valid + fieldset': {
//         borderColor: '#1ac3a3',
//       },
//       '& label.Mui-focused': {
//         color: '#1ac3a3',
//       },
//       '& .MuiOutlinedInput-root': {
//         '& fieldset': {
//           borderColor: '#1ac3a3',
//         },
//         '&:hover fieldset': {
//           borderColor: '#1ac3a3',
//         },
//         '&.Mui-focused fieldset': {
//           borderColor: '#1ac3a3',
//         },
//       },
//       '& input:invalid + fieldset': {
//         borderColor: 'red',
//       },
//       '& input:valid:focus + fieldset': {
//         // borderLeftWidth: 6,
//         // padding: '4px !important', // override inline-style
//       },
//     },
// })(TextField);

const App = (props) => {
    const classes = useStyles(props)

    const customInit = async (engine) => {
      // this adds the preset to tsParticles, you can safely use the
      await loadTrianglesPreset(engine);
    }
    return (
      <div className={classes.bg}>
        <Router>
          <Switch>
            <Route path="/" exact component={SignIn}/>
            <Route path="/signin" component={SignIn}/>
            <Route path="/signup" component={SignUp}/>
            <Route path="/forgot" component={Forgot}/>
            <Route path="/verify" component={Verify}/>
            <Route path="/consent" component={Consent}/>
            <Route path="/resetPassword" component={Reset}/>
            <Route path="/userOrg" component={UserOrg}/>
            <Route path="/redirect" component={(props) => {
              window.location.href = props.location.state.redirectLink;
              return null;
            }}/>
          </Switch>
        </Router>
      <Particles
        className={clsx(classes.bg, classes.canvas)}
        id="tsparticles"
        init={customInit}
        options={{
          background: {
            color: {
              value: "#fff",
            },
          },
          preset: "triangles",
          "particles": {
              "number": {
                  "value": 50
              },
              "size": {
                  "value": 3
              },
              "color": {
                "value": "#5dc38d"
              },
              "line_linked": {
                "enable": true,
                "distance": 140,
                "color": "#5dc38d",
                "opacity": 0.4,
                "width": 2
              },
          },
          "interactivity": {
              "detect_on": "canvas",
              "events": {
                  "onhover": {
                      "enable": true,
                      "mode": "grab"
                  }
              }
          }
      }}/>
      </div>
    )
}

export default App
