import React from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import clsx from 'clsx';
import axios from 'axios';
import { useHistory } from "react-router-dom"

import logo from '../../logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    error: {
        fontSize: 'small',
        color: 'red'
    },
    btn: {
        color: '#b1ffb1'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em',
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
  loadingBox: {
    background: 'white',
    borderRadius: '4px',
    boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
    padding: '2.8em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ProgressCircle = withStyles({
    root: {
        color: '#5DE455',
        position: 'relative',
    }
  })(CircularProgress)

const Consent = (props) => {
    const classes = useStyles(props)
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [mounted, setMounted] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const [jwt, setJwt] = React.useState('');
    const [user, setUser] = React.useState({});
    const onMount = async () => {
        if (!mounted) {
            setMounted(true)
            let tempClientId = ''
            let tempRedirectUrl = ''
            let tempArr = window.location.href.split('?')
            if (tempArr[1]) {
              tempArr = tempArr[1].split('&')
              tempArr = tempArr.map((query) => {
                  const nestedArr = query.split('=')
                  if (nestedArr[0] === 'redirectUrl') {
                      setRedirectUrl(nestedArr[1])
                      tempRedirectUrl = nestedArr[1]
                  }
                  if (nestedArr[0] === 'clientId') {
                      setClientId(nestedArr[1])
                      tempClientId = nestedArr[1]
                  }
                  return nestedArr
              })
            }
            if (props.location.state.jwt) setJwt(props.location.state.jwt)
            if (props.location.state.user) setUser(props.location.state.user)
            // Current implementation without checking clientId and redirectUrl
            try {
                const consentRes = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/consent`, {
                    authJwt: props.location.state.jwt,
                    clientId: tempClientId,
                    consent: {
                        profile: true,
                        email: true
                    },
                    redirectTo: tempRedirectUrl
                })
                if (isNaN(consentRes.data.code)) {
                    history.push({
                      pathname: "/redirect",
                      state: {
                        redirectLink: tempRedirectUrl + "?code=" + consentRes.data.id
                      }
                    })
                } else {
                    setLoading(false)
                    setErrorMessage('Failed to verify consent. Please contact support')
                }
            } catch (err) {
                setLoading(false)
            }
        }
    }
    onMount()
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize</Typography>
          {/* <Box m={2} variant="middle" className={classes.box} > */}
          {/* <Typography variant="h5" className={classes.text}>Consent</Typography><br/>
          <Typography variant="h6" className={classes.text}>Do you want share your email and profile with Liberalize</Typography> */}
          {/* Have to fetch clientId on mount and display company Name */}
          {/* </Box> */}
          <Box m={2} variant="middle" className={classes.loadingBox} >
            {loading && <ProgressCircle/>}
            {!loading && errorMessage && <Typography className={classes.error}>{errorMessage}</Typography>}
          </Box>
      </Container>
    )
}

export default Consent
