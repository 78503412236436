import React from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
// import clsx from 'clsx';
import axios from 'axios';
import { Link, useHistory } from "react-router-dom"
import logo from '../../logo.svg'


const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    btn: {
        color: '#b1ffb1'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em'
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
  error: {
    fontSize: 'small',
    color: 'red'
  },
}));

const GreenProgressCircle = withStyles({
  root: {
      color: '#1ac3a3',
      marginLeft: '42%'
  }
})(CircularProgress)

const ProgressCircle = withStyles({
  root: {
      color: 'white'
  }
})(CircularProgress)

const ValidationTextField = withStyles({
    root: {
        margin: '1em auto',
      '& input:valid + fieldset': {
        borderColor: '#1ac3a3',
      },
      '& label.Mui-focused': {
        color: '#1ac3a3',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#1ac3a3',
        },
        '&:hover fieldset': {
          borderColor: '#1ac3a3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1ac3a3',
        },
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
      },
      '& input:valid:focus + fieldset': {
        // borderLeftWidth: 6,
        // padding: '4px !important', // override inline-style
      },
    },
})(TextField);

const SignIn = (props) => {
    const classes = useStyles(props)
    const history = useHistory();
    const [mounted, setMounted] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const [emailPasswordBox, setEmailPasswordBox] = React.useState(true);
    const [otpBox, setOtpBox] = React.useState(false);
    const [resendVerificationBox, setResendVerificationBox] = React.useState(false);
    const [verificationEmailError, setVerificationEmailError] = React.useState('');
    const [verificationEmailSent, setVerificationEmailSent] = React.useState(false);
    const onMount = async () => {
            if (!mounted) {
                setMounted(true)
                let tempArr = window.location.href.split('?')
                if (tempArr[1]) {
                  tempArr = tempArr[1].split('&')
                  tempArr = tempArr.map((query) => {
                      const nestedArr = query.split('=')
                      if (nestedArr[0] === 'redirectUrl') setRedirectUrl(nestedArr[1])
                      if (nestedArr[0] === 'clientId') setClientId(nestedArr[1])
                      return nestedArr
                  })
                }
            }
    }
    onMount()
    const [email, setEmail] = React.useState('');
    const emailChange = (event) => {
      setEmail(event.target.value.toLowerCase())
    }
    const [password, setPassword] = React.useState('');
    const passwordChange = (event) => {
      setPassword(event.target.value)
    }
    const [emailOTP, setEmailOTP] = React.useState('');
    const otpChange = (event) => {
      setEmailOTP(event.target.value)
    }
    const [loading, setLoading] = React.useState(false);
    const [unmounted, setUnmounted] = React.useState(false);
    const [loginError, setLoginError] = React.useState('');
    const sendEmailOtp = async (event) => {
      setLoginError('')
      setLoading(true)
      try {
      // Check Email Verified
      const { data: { isVerified } } = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/userIsVerified`, {
        "email": email,
      })

      if (!isVerified) {
        setLoading(false)
        setEmailPasswordBox(false)
        setResendVerificationBox(true)
        return
      }
      } catch (error) {
        setLoginError('Error Verifying User')
      }
      try {
        const sendEmailOtpRes = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/otp`, {
          "email": email,
          "type": "email"
        })
        if (sendEmailOtpRes.status >= 200 && sendEmailOtpRes.status < 300 ) {
          setLoginError('')
          setEmailPasswordBox(false)
          setTimeout(() => {
            setLoading(false)
            setOtpBox(true)
          }, 870);
        } else {
          setLoading(false)
          setLoginError('No Such User')
        }
      } catch (err) {
        setLoading(false)
        setLoginError('Error Sending OTP Email')
      }
    }
    const backToEmailPass = async (event) => {
      setOtpBox(false)
      setResendVerificationBox(false)
      setEmailOTP('')
      setPassword('')
      setLoginError('')
      setEmail()
      setTimeout(() => {
        setEmailPasswordBox(true)
      }, 870);
    }

    const resendVerificationEmail = async (event) => {
      try {
        const { data: emailSent } = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/resendVerification`, {
          "email": email,
          "redirectUrl": redirectUrl,
          "clientId": clientId
        })

        if(!emailSent) {
          setVerificationEmailError(`An error occured while trying to resend the verifcation email. If the problem persists, contact us at support@liberalize.io`)
        } else {
          setVerificationEmailSent(true)
        }

      } catch (error) {
        setVerificationEmailError(`An error occured while trying to resend the verifcation email. If the problem persists, contact us at support@liberalize.io`)
      }
    }

    const login = async (event) => {
      if (!unmounted) {
        setLoading(true)
        setLoginError('')
        try {
          const loginRes = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/login`, {
            "email": email,
            "password": password,
            "otp": emailOTP
          })
          if ((loginRes.data && loginRes.data.jwt && loginRes.data.jwt.length < 50)
            || !loginRes.data.jwt) {
            console.log('loginRes -> ', loginRes)
            setLoginError(`${loginRes.data.message}`)
            setLoading(false)
          } else {
            setLoading(false)
            setUnmounted(true)
            history.push({
                pathname: '/consent',
                search: `?${redirectUrl? `redirectUrl=${redirectUrl}` : ''}${clientId ? `&clientId=${clientId}`: ''}`,
                state: {
                  jwt: loginRes.data.jwt,
                  user: loginRes.data.user,
                }
            })
          }
        } catch (err) {
          switch ((err.response && err.response.data && err.response.data.code) || 0) {
            case 7:
            case 8:
              setLoginError(`Invalid Verification Code`)
              break;
            case 4:
              setLoginError(`Invalid Email or  Password`)
              break;
            default:
              setLoginError(`Invalid Login Credentials`)
              break;
          }
          setLoading(false)
        }
      }
    }
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize SSO</Typography>
          {/* Email and user name */}
          <Slide direction="up" in={emailPasswordBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Sign in</Typography>
                <ValidationTextField
                    disabled={loading}
                    error={false}
                    onChange={emailChange}
                    className={classes.root}
                    label="Email"
                    fullWidth
                    variant="outlined"
                />
                <ValidationTextField
                    onChange={passwordChange}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Password"
                    fullWidth
                    type="password"
                    variant="outlined"
                />
                <Typography ><Link className={classes.green} to={`/forgot?${redirectUrl ? "redirectUrl="+redirectUrl:''}${clientId ? `&clientId=${clientId}` : ''}`}>Forgot your password?</Link></Typography><br/>
                <Button disabled={loading} onClick={sendEmailOtp} className={classes.introBtn}>{loading ? <ProgressCircle/> : 'Continue'}</Button><br/><br/>
                <span className={classes.error}>{loginError ? loginError : ''}</span>
                <Typography className={classes.text}>Don't have an account? <Link className={classes.green} to={`/signup?${redirectUrl ? "redirectUrl="+redirectUrl:''}${clientId ? `&clientId=${clientId}` : ''}`}>Sign up</Link></Typography> 
            </Box>
          </Slide>
          {/* Verification Code */}
          <Slide direction="up" in={otpBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Multi-factor Authentication</Typography>
                <Typography variant="h7" className={classes.text}>A verification code was sent to your email address. Please proceed by verifying with the verification code.</Typography>
                <ValidationTextField
                    onChange={otpChange}
                    disabled={loading}
                    error={false}
                    className={classes.root}
                    label="Verification Code"
                    fullWidth
                    variant="outlined"
                />
                {loading && <GreenProgressCircle/>}
                {!loading && <Button disabled={loading} onClick={login} className={classes.introBtn}>{loading ? <ProgressCircle/> : 'Proceed to Sign In'}</Button>}<br/><br/>
                {!loading && <Button disabled={loading} onClick={backToEmailPass} className={classes.introBtn}>{loading ? <ProgressCircle/> : 'Back'}</Button>}<br/><br/>
                <span className={classes.error}>{loginError ? loginError : ''}</span>
            </Box>
          </Slide>
          {/* Resend Verification */}
          <Slide direction="up" in={resendVerificationBox} mountOnEnter unmountOnExit>
            <Box m={2} variant="middle" className={classes.box} >
                <Typography variant="h5" className={classes.text}>Your account is not verified.</Typography>
                {!loading &&verificationEmailSent &&<Typography variant="h7" className={classes.text}>We've sent a verification email to {`${email}`}. Please continue your account login with the link from the email sent.</Typography>}
                {!verificationEmailSent && <Typography variant="h7" className={classes.text}>By clicking on the resend button below, you will receive a verification email if the account exists.</Typography>}
                {!verificationEmailSent &&<Typography variant="h7" className={classes.text}> Do check your junk or spam mails, and mark as not junk or spam in order to receive emails in the future.</Typography>}<br/><br/>
                {loading && <GreenProgressCircle/>}
                {!loading && !verificationEmailSent && <Button disabled={loading} onClick={resendVerificationEmail} className={classes.introBtn}>{loading ? <ProgressCircle/> : 'Resend Verfication Email'}</Button>}<br/><br/>
                {!loading && !verificationEmailSent && <Button disabled={loading} onClick={backToEmailPass} className={classes.introBtn}>{loading ? <ProgressCircle/> : 'Back'}</Button>}<br/><br/>
                <span className={classes.error}>{verificationEmailError ? verificationEmailError : ''}</span>
            </Box>
          </Slide>
      </Container>
    )
}

export default SignIn
