import React from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom"
// import clsx from 'clsx';
import axios from 'axios';

import logo from '../../logo.svg'
import { ValidateEmail, ValidatePassword } from '../../utils'

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    btn: {
        color: '#b1ffb1'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    titleCenter: {
      textAlign: 'center',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    error: {
      fontSize: 'small',
      color: 'red'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em'
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
}));

const ProgressCircle = withStyles({
  root: {
      color: 'white'
  }
})(CircularProgress)

const ValidationTextField = withStyles({
    root: {
        margin: '1em auto',
      '& input:valid + fieldset': {
        borderColor: '#1ac3a3',
      },
      '& label.Mui-focused': {
        color: '#1ac3a3',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#1ac3a3',
        },
        '&:hover fieldset': {
          borderColor: '#1ac3a3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1ac3a3',
        },
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
      },
      '& input:valid:focus + fieldset': {
        // borderLeftWidth: 6,
        // padding: '4px !important', // override inline-style
      },
    },
})(TextField);

const SignUp = (props) => {
    const [userCreated, setUserCreated] = React.useState('');
    const [generalError, setGeneralError] = React.useState('');
    const classes = useStyles(props)
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const emailChange = (event) => {
      setEmail(event.target.value.toLowerCase())
      const validation = ValidateEmail(event.target.value)
      if (!validation) setEmailError(true)
      if (validation) setEmailError(false)
    }
    const [firstName, setFirstName] = React.useState('');
    const [firstNameError, setFirstNameError] = React.useState(false);
    const firstNameChange = (event) => {
      setFirstName(event.target.value)
      if (event.target.value.length < 1) setFirstNameError(true)
      if (event.target.value.length > 1) setFirstNameError(false)
    }
    const [lastName, setLastName] = React.useState('');
    const [lastNameError, setLastNameError] = React.useState(false);
    const lastNameChange = (event) => {
      setLastName(event.target.value)
      if (event.target.value.length < 1) setLastNameError(true)
      if (event.target.value.length > 1) setLastNameError(false)
    }
    const [password, setPassword] = React.useState('');
    const [passwordErrMessage, setPasswordErrMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const passwordChange = (event) => {
      setPassword(event.target.value)
      const validation = ValidatePassword(event.target.value)
      if (!validation) {
        setPasswordErrMessage('Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.')
        setPasswordError(true)
      } else {
        setPasswordErrMessage('')
        setPasswordError(false)
      }
    }
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordErrMessage, setConfirmPasswordErrMessage] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const confirmPasswordChange = (event) => {
      setConfirmPassword(event.target.value)
      const validation = event.target.value === password
      if (!validation) {
        setConfirmPasswordErrMessage('Passwords do not match')
        setConfirmPasswordError(true)
      } else {
        setConfirmPasswordErrMessage('')
        setConfirmPasswordError(false)
      }
    }
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const CreateUser = async () => {
      setGeneralError('')
      setLoading(true)
      if (confirmPasswordError || passwordErrMessage || lastNameError || firstNameError || emailError) {
        setLoading(false)
        return
      }
      try {
        const createUserRes = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/user`, {
          "email": email,
          "firstName": firstName,
          "lastName": lastName,
          "password": password,
          "redirectUrl": redirectUrl,
          "clientId": clientId,
        })        
        if (createUserRes.data.code) {
          setGeneralError(`${createUserRes.data.message}`)
        } else {
          setUserCreated(createUserRes.data.user.email)
        }
      } catch (err) {
        setLoading(false)
      }
      setLoading(false)
    }
    const [mounted, setMounted] = React.useState(false);
    const onMount = async () => {
          if (!mounted) {
              setMounted(true)
              if (window.location.href.indexOf('redirectUrl') > 1) {
                // seperate query parameter
                let tempArr = window.location.href.split('?')
                // seperate search fields
                tempArr = tempArr[1].split('&')
                tempArr.map((query) => {
                  const nestedArr = query.split('=')
                  if (nestedArr[0] === 'redirectUrl') setRedirectUrl(nestedArr[1])
                  if (nestedArr[0] === 'clientId') setClientId(nestedArr[1])
                  return nestedArr
                })
              }
          }
    }
    onMount()
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize</Typography>
          {!userCreated && <Box m={2} variant="middle" className={classes.box} >
              <Typography variant="h5" className={classes.text}>Create your account</Typography><br/>
              <Typography variant="body1" className={classes.text}>Already have an account? <Link className={classes.green} to={`/signin?${redirectUrl ? 'redirectUrl='+redirectUrl : '' }${clientId ? `&clientId=${clientId}` : ''}`}>Sign in</Link></Typography><br/>
              <ValidationTextField
                  onChange={emailChange}
                  disabled={loading}
                  error={emailError}
                  className={classes.root}
                  label="Email"
                  fullWidth
                  variant="outlined"
              />
              <ValidationTextField
                  onChange={firstNameChange}
                  disabled={loading}
                  error={firstNameError}
                  className={classes.root}
                  label="First Name"
                  fullWidth
                  variant="outlined"
              />
              <ValidationTextField
                  onChange={lastNameChange}
                  disabled={loading}
                  error={lastNameError}
                  className={classes.root}
                  label="Last Name"
                  fullWidth
                  variant="outlined"
              />
              <ValidationTextField
                  onChange={passwordChange}
                  disabled={loading}
                  error={passwordError}
                  className={classes.root}
                  label="Password"
                  fullWidth
                  type="password"
                  variant="outlined"
              />
              <span className={classes.error}>{passwordErrMessage}</span>
              <ValidationTextField
                  onChange={confirmPasswordChange}
                  disabled={loading}
                  error={confirmPasswordError}
                  className={classes.root}
                  label="Confirm Password"
                  fullWidth
                  type="password"
                  variant="outlined"
              />
              <span className={classes.error}>{confirmPasswordErrMessage}</span>
              {confirmPasswordErrMessage ? <span><br/><br/></span> : ''}
              <Button disabled={loading} onClick={CreateUser} className={classes.introBtn}>{loading ? <ProgressCircle/> : 'Create your Liberalize Account'}</Button><br/><br/>
              {generalError ? <span className={classes.error}>{generalError}</span> : ''}
          </Box>}
          {
            userCreated && <Box m={2} variant="middle" className={classes.box} >
              <Typography variant="h5" className={classes.titleCenter}>Your account has been created!</Typography><br/>
              <Typography className={classes.text}>
                  {`We've sent a verification email to ${userCreated}. Please continue your account creation with the link from the email sent.`}
              </Typography><br/>
            </Box>
          }
      </Container>
    )
}

export default SignUp
