import React from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import clsx from 'clsx';
import axios from 'axios';
import { Link } from "react-router-dom"

import logo from '../../logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    error: {
        fontSize: 'small',
        color: 'red'
    },
    btn: {
        color: '#b1ffb1'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
}));

const ProgressCircle = withStyles({
    root: {
        color: '#5DE455',
        position: 'relative',
    }
  })(CircularProgress)

const Verify = (props) => {
    const classes = useStyles(props)
    const [loading, setLoading] = React.useState(true);
    const [verified, setVerified] = React.useState(false);
    const [verificationErr, setVerificationErr] = React.useState('');
    const [mounted, setMounted] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const onMount = async () => {
            if (!mounted) {
                setMounted(true)
                let jwt = ''
                let tempArr = window.location.href.split('?')
                tempArr = tempArr[1].split('&')
                tempArr = tempArr.map((query) => {
                    const nestedArr = query.split('=')
                    if (nestedArr[0] === 'jwt') jwt = nestedArr[1]
                    if (nestedArr[0] === 'redirectUrl') setRedirectUrl(nestedArr[1])
                    if (nestedArr[0] === 'clientId') setClientId(nestedArr[1])
                    return nestedArr
                })
                try {
                    const verificationRes = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/verification`, {
                        jwt: jwt
                    })
                    if (verificationRes.status >= 200 && verificationRes.status < 300) {
                        setVerified(true)
                        setLoading(false)
                    } 
                    if (verificationRes.data.code) {
                        setVerified(false)
                        setLoading(false)
                        setVerificationErr('Error verifying email. Please contact support.')
                    }
                } catch (err) {
                    setLoading(false)
                    setVerified(false)
                    setVerificationErr('Error verifying email. Please contact support.')
                }
            }
    }
    onMount()
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize</Typography>
          <Box m={2} variant="middle" className={classes.box} >
                {loading ? <div><ProgressCircle/><br/><br/></div> : ''}
                {loading ? <Typography variant="h5" className={classes.text}>Verifying</Typography> : '' }
                {/* <Button className={classes.introBtn}>Continue</Button><br/><br/> */}
                {!loading && verified ? <div><Typography variant="h5" className={classes.title}>Email Verified</Typography><br/><br/></div> : '' }
                {!loading && verified ? <Typography className={classes.text}>Continue to <Link className={classes.green} to={`/signin?${redirectUrl ? "redirectUrl=" + redirectUrl : ''}${clientId ? `&clientId=${clientId}` : ''}`}>Sign In</Link></Typography> : '' }
                {!loading && !verified ? <Typography className={classes.error}>{verificationErr}</Typography> : '' }
          </Box>
      </Container>
    )
}

export default Verify
