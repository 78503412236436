function ValidateEmail(email) 
{
 if (/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) //  if (/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
  {
    return (true)
  }
    return (false)
}

function ValidatePassword(password) 
{
 if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password))
  {
    return (true)
  }
    return (false)
}

module.exports = {
    ValidateEmail: ValidateEmail,
    ValidatePassword: ValidatePassword
}