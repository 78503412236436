import React from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import clsx from 'clsx';
import axios from 'axios';
import { Link } from "react-router-dom"

import logo from '../../logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    btn: {
        color: '#b1ffb1'
    },
    error: {
      fontSize: 'small',
      color: 'red'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em'
    },
    boxCenter: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
}));

const ValidationTextField = withStyles({
    root: {
        margin: '1em auto',
      '& input:valid + fieldset': {
        borderColor: '#1ac3a3',
      },
      '& label.Mui-focused': {
        color: '#1ac3a3',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#1ac3a3',
        },
        '&:hover fieldset': {
          borderColor: '#1ac3a3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1ac3a3',
        },
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
      },
      '& input:valid:focus + fieldset': {
        // borderLeftWidth: 6,
        // padding: '4px !important', // override inline-style
      },
    },
})(TextField);

const ProgressCircle = withStyles({
  root: {
      color: 'white'
  }
})(CircularProgress)

const Forgot = (props) => {
    const classes = useStyles(props)
    const [mounted, setMounted] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState('');
    const [clientId, setClientId] = React.useState('');
    const onMount = async () => {
            if (!mounted) {
                setMounted(true)
                let tempArr = window.location.href.split('?')
                if (tempArr[1]) {
                  tempArr = tempArr[1].split('&')
                  tempArr = tempArr.map((query) => {
                      const nestedArr = query.split('=')
                      if (nestedArr[0] === 'redirectUrl') setRedirectUrl(nestedArr[1])
                      if (nestedArr[0] === 'clientId') setClientId(nestedArr[1])
                      return nestedArr
                  })
                }
            }
    }
    onMount()
    const [email, setEmail] = React.useState('');
    const emailChange = (event) => {
      setEmail(event.target.value.toLowerCase())
    }
    const [loading, setLoading] = React.useState(false);
    const [emailSent, setEmailSent] = React.useState(false);
    const [forgotError, setForgotError] = React.useState('');
    const forgot = async (event) => {
        setLoading(true)
        setForgotError('')
        try {
          const forgotRes = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/password/forget`, {
            email,
            redirectUrl,
            clientId
          })
          if (!isNaN(forgotRes.data.code)) {
            setForgotError(`${forgotRes.data.message}`)
            setLoading(false)
          } else {
            setLoading(false)
            setEmailSent(true)
          }
        } catch (err) {
          setForgotError(`${err || "An error occured while sending your forget password email. Please contact support."}`)
          setLoading(false)
          setEmailSent(false)
        }
    }
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize</Typography>
          {!emailSent && <Box m={2} variant="middle" className={classes.box} >
              <Typography className={classes.text}>Enter the email address associated with your account and we'll send you a link to reset your password.</Typography> 
              <ValidationTextField
                  onChange={emailChange}
                  disabled={loading}
                  error={false}
                  className={classes.root}
                  label="Email"
                  fullWidth
                  variant="outlined"
                  id="validation-outlined-input"
              />
              {!loading && forgotError ? <Typography className={classes.error}>{forgotError}</Typography> : '' }
              <Button className={classes.introBtn} onClick={forgot}>{loading ? <ProgressCircle/> : 'Continue'}</Button><br/><br/>
              <Typography className={classes.text}>Don't have an account? <Link className={classes.green} to={`/signup?${redirectUrl ? "redirectUrl="+redirectUrl:''}${clientId ? `&clientId=${clientId}` : ''}`}>Sign up</Link></Typography> 
          </Box>}
          {emailSent && <Box m={2} variant="middle" className={classes.boxCenter} >
              <Typography variant="h5" className={classes.title}>Password Reset Email Sent</Typography><br/><br/>
              <Typography className={classes.text}>Back to <Link className={classes.green} to={`/signin?${redirectUrl ? "redirectUrl="+redirectUrl:''}${clientId ? `&clientId=${clientId}` : ''}`}>Sign in</Link></Typography> 
          </Box>}
      </Container>
    )
}

export default Forgot
