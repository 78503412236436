import React, { useState, useLayoutEffect, useEffect } from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { Route, Switch } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import clsx from 'clsx';
import axios from 'axios';
import { useHistory } from "react-router-dom"

import logo from '../../logo.svg'
import { ValidatePassword } from '../../utils'
const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#dbffdf important!',
        color: '#b1ffb1 important!'
    },
    btn: {
        color: '#b1ffb1'
    },
    error: {
      fontSize: 'small',
      color: 'red'
    },
    title: {
      textAlign: 'left',
      color: '#1ac3a3',
      textShadow: '4px 4px 4px rgba(60, 66, 87, 0.21) ',
      display: 'inline-block'
    },
    logo: {
      position: 'relative',
      top: '0.3em',
      maxWidth: '2em',
      marginLeft: 16,
    },
    green: {
      color: '#1ac3a3',
      textDecoration: 'none'
    },
    text: {
      color: '#3c4257'
    },
    container: {
      background: 'rgb(255, 255, 255, 0)',
      maxWidth: '780px',
      marginTop: '2em',
    },
    box: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em'
    },
    boxCenter: {
      background: 'white',
      borderRadius: '4px',
      boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px',
      padding: '2.8em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    bg: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    canvas: {
      zIndex: -1000
    },
    introBtn: {
      width: '100%',
      color: '#ffffff',
      borderWidth: '0px',
      letterSpacing: '1px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)'
  },
}));

const TextFieldMod = withStyles({
    root: {
        margin: '1em auto',
      '& input:valid + fieldset': {
        borderColor: '#1ac3a3',
      },
      '& label.Mui-focused': {
        color: '#1ac3a3',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#1ac3a3',
        },
        '&:hover fieldset': {
          borderColor: '#1ac3a3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1ac3a3',
        },
      },
      '& input:invalid + fieldset': {
        borderColor: 'red',
      },
      '& input:valid:focus + fieldset': {
        // borderLeftWidth: 6,
        // padding: '4px !important', // override inline-style
      },
    },
})(TextField);

const ProgressCircle = withStyles({
  root: {
      color: 'white'
  }
})(CircularProgress)

const UserOrg = (props) => {
    const classes = useStyles(props)
    const [redirectUrl, setRedirectUrl] = useState('');
    const [clientId, setClientId] = useState('');
    const [userOrgJwt, setUserOrgJwt] = useState('');
    const [consoleEnv, setConsoleEnv] = useState('');
    const [organization, setOrganization] = useState('');
    const [orgName, setOrgName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const history = useHistory();
    useLayoutEffect(() => {
        let initialUrl = window.location.href
        let urlArr = initialUrl.split('?')[1]
        urlArr = urlArr.split("&")
        urlArr.forEach((query)=> {
            let keyPairArr = query.split('=')
            switch (keyPairArr[0]) {
                case 'jwt':
                    setUserOrgJwt(keyPairArr[1])
                    break;
                case 'email':
                    setEmail(keyPairArr[1])
                    break;
                case 'organization':
                    setOrganization(keyPairArr[1])
                    break;
                case 'redirectUrl':
                    setRedirectUrl(keyPairArr[1])
                    break;
                case 'role':
                    setRole(keyPairArr[1])
                    break;
                case 'clientId':
                    setClientId(keyPairArr[1])
                    break;
                case 'env':
                    setConsoleEnv(keyPairArr[1])
                    break;
                case 'orgName':
                    setOrgName(keyPairArr[1])
                    break;
                default:
                    break;
            }
        })
    },[])
    const [userExist, setUserExist] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(()=> {
        setLoading(true)
        try {
            axios.get(`${process.env.REACT_APP_SSO_API_URL}/userExist`, {
                headers: {"Authorization": "Bearer " + userOrgJwt}
            }).then((result)=> {
                console.log("result => ", result.data);
                if (result.data && result.data.isExistingUser) {
                    setUserExist(true)
                    axios.post(
                        `${consoleEnv === 'staging' ? process.env.REACT_APP_STAGING_CONSOLE_API_URL: process.env.REACT_APP_PROD_CONSOLE_API_URL}/organizations/${organization}/users`, {
                            userId: result.data.userId,
                            userEmail: email,
                            organizationId: organization,
                            organizationName: orgName,
                            roleId: role
                        }, {
                            headers: {"Authorization": "Bearer " + userOrgJwt}
                        }).then((result)=> {
                            setLoading(false)
                            console.log("result of user org creation => ", result);
                            if (result.data && result.data.userId) {
                                history.push({
                                    pathname: '/signin',
                                    search: `?${redirectUrl? `redirectUrl=${redirectUrl}` : ''}${clientId ? `&clientId=${clientId}`: ''}`,
                                })
                            }
                        })
                } else {
                    setLoading(false)
                }
            })
        } catch (error) {
            setLoading(false)
            setErrorMessage(error)
        } finally {
          setLoading(false)
        }
    },[consoleEnv, userOrgJwt])
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [unmatchPassword, setUnmatchPassword] = useState(false);
    const confirmPasswordChange = (event) => {
        setUnmatchPassword(false)
        setConfirmPassword(event.target.value)
        if (event.target.value !== password) {
            setUnmatchPassword(true)
        }
    }
    const [newUserError, setNewUserError] = useState('');
    const [passwordErrMessage, setPasswordErrMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const passwordChange = (event) => {
        setPassword(event.target.value)
        const validation = ValidatePassword(event.target.value)
        if (!validation) {
          setPasswordErrMessage('Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.')
          setPasswordError(true)
        } else {
          setPasswordErrMessage('')
          setPasswordError(false)
        }
      }
    const createUserOrg = async (event) => {
        setLoading(true)
        try {
            const newUser = await axios.post(`${process.env.REACT_APP_SSO_API_URL}/userAccessC`, {
                email,
                redirectUrl,
                clientId,
                firstName,
                lastName,
                password: confirmPassword
            }, {
                headers: {"Authorization": "Bearer " + userOrgJwt}
            }
            )
            console.log('newUser => ', newUser);
            if (newUser.data && newUser.data.jwt && newUser.data.jwt.length > 10) {
                // the request body does not matter ultimately we are validating from the backend
                const newUserOrg = await axios.post(
                    `${consoleEnv === 'staging' ? process.env.REACT_APP_STAGING_CONSOLE_API_URL: process.env.REACT_APP_PROD_CONSOLE_API_URL}/organizations/${organization}/users`, {
                        userId: newUser.data.user.id,
                        userEmail: newUser.data.user.email,
                        organizationId: organization,
                        organizationName: orgName,
                        roleId: role
                    }, {
                        headers: {"Authorization": "Bearer " + userOrgJwt}
                    }
                    )
                    console.log('newUserOrg => ', newUserOrg);
                    if (newUserOrg.data && newUserOrg.data.userId) {
                        history.push({
                            pathname: '/signin',
                            search: `?${redirectUrl? `redirectUrl=${redirectUrl}` : ''}${clientId ? `&clientId=${clientId}`: ''}`,
                        })
                    }
                    setLoading(false)
                } else {
                    setNewUserError(`${newUser.data.message}`)
                    setLoading(false)
                }
            } catch (err) {
                setNewUserError(`${err || "An error occured while sending your forget password email. Please contact support."}`)
                setLoading(false)
            }
    }
    return (
      <Container maxWidth="xl" className={classes.container} >
          <img className={classes.logo} alt="liberalize-logo" src={logo}/><Typography variant="h6" className={classes.title}>Sign in with Liberalize</Typography>
            {userExist && <Box m={2} variant="middle" className={classes.box}>
                {loading ? <div><ProgressCircle/><br/><br/></div> : ''}
                {errorMessage ? <Typography className={classes.error}>{errorMessage}</Typography> : ''}
            </Box>}
          {!userExist && <Box m={2} variant="middle" className={classes.box} >
              <Typography className={classes.text}>Create a Liberalize account for {email}. Please enter the following details for this account.</Typography>
              <TextFieldMod
                  onChange={(e) => { setFirstName(e.target.value)}}
                  disabled={loading}
                  className={classes.root}
                  label="First Name"
                  fullWidth
                  variant="outlined"
              />
              <TextFieldMod
                  onChange={(e) => { setLastName(e.target.value)}}
                  disabled={loading}
                  className={classes.root}
                  label="Last Name"
                  fullWidth
                  variant="outlined"
              />
              <TextFieldMod
                  onChange={passwordChange}
                  disabled={loading}
                  error={passwordError}
                  className={classes.root}
                  label="New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
              />
              <TextFieldMod
                  onChange={confirmPasswordChange}
                  disabled={loading}
                  error={unmatchPassword}
                  className={classes.root}
                  label="Confirm New Password"
                  type="password"
                  fullWidth
                  variant="outlined"
              />
              {(errorMessage || passwordError) ? <Typography className={classes.error}>{errorMessage || passwordErrMessage}</Typography> : ''}
              {unmatchPassword ? <Typography className={classes.error}>Passwords do not match</Typography> : '' }
              <Button className={classes.introBtn} disabled={loading} onClick={createUserOrg}>{loading ? <ProgressCircle/> : 'Continue'}</Button><br/><br/>
    {newUserError ? <Typography className={classes.error}>{newUserError}</Typography> : ''}
          </Box>}
      </Container>
    )
}

export default UserOrg
